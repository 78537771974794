import React, { useEffect } from "react";
import props from "../data/GermanData";
import Hero from "../components/sites/Hero";
import Services from "../components/sites/Services";
import Team from "../components/sites/Team";
import Faq from "../components/sites/Faq";
import Network from "../components/sites/Network";
import teamImg from "../assets/img/TeamPalliativHausLebenFalkenberg27.webp";

function Main() {
  useEffect(() => {
    window.onscroll = function () {
      scrollFunction();
    };
  }, []);

  function scrollFunction() {
    let navDesktop = document.querySelector(".nav-dektop");
    let lowerNavMenu = document.querySelector(".nav-lower-links-container");
    let navLogo = document.querySelector(".nav-logo");

    if (
      document.body.scrollTop > 400 ||
      document.documentElement.scrollTop > 400
    ) {
      navDesktop.setAttribute("data-type", "active");
      lowerNavMenu.setAttribute("data-type", "inactive");
      renderDesktopMenu(true);
    } else {
      navDesktop.removeAttribute("data-type");
      lowerNavMenu.removeAttribute("data-type");
      renderDesktopMenu(false);
    }
  }

  const renderDesktopMenu = (r) => {
    let desktopMenu = document.querySelector(".nav-desktop-menu");
    if (desktopMenu) {
      if (r) {
        desktopMenu.removeAttribute("data-type");
      } else {
        desktopMenu.setAttribute("data-type", "inactive");
      }
    }
  };

  return (
    <>
      <section id="home">
        <Hero props={props} />
      </section>
      <section id="services">
        <Services props={props} />
      </section>
      <section id="team">
        <div className="plhl-container" data-type="emergency">
          <div className="plhl-bg-trans padding-inline">
            <div className="plhl-container-header">
              {props.notrufPlaceholder.title}
            </div>
          </div>
          <div className="plhl-bg-img"></div>
        </div>
        <Team props={props} />
        <div className="team-img-wrapper">
          <img alt="Teambild" src={teamImg} className="team-img"></img>
        </div>
      </section>
      <section id="faq">
        <Faq props={props} />
      </section>
      <section id="network">
        <Network props={props} />
      </section>
    </>
  );
}

export default Main;
